<template>
  <div class="student_center">
    <el-tabs type="card" v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="學生主頁" name="ChargeMeTribe_StudentProfile"></el-tab-pane>
      <el-tab-pane label="學生行事曆" name="ChargeMeTribe_StudentCalendar"></el-tab-pane>
      <el-tab-pane label="學生課程" name="ChargeMeTribe_StudentClass"></el-tab-pane>
    </el-tabs>
  </div>
  <router-view />
</template>

<script>
export default {
  name: "StudentCenter",
  data() {
    return {
      activeName: this.$route.name,
    };
  },
  methods: {
    handleClick(tab) {
      this.$router.push(`/ChargeMeTribe/StudentCenter/${tab.props.name}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.student_center {
  padding: 1rem 1.5rem 0;
}
</style>
